import { AbsoluteContainer, Container, Overlay } from "@util/standard";
import {
  AdvertBoard,
  AppLayout,
  EditorMenu,
  EditorPicker,
  EditorTool,
  TextEditorProvider,
} from "@shared/salesTool";
import {
  AdvertBoardWrapper,
  AdvertContainer,
  ImageElement,
  RndStyle,
  Shape,
  TextElement,
} from "@shared/salesTool/components.styles";
import React, { createRef, useEffect, useRef, useState } from "react";
import {
  getAdCopyToSave,
  getFileFromCache,
  isBrowser,
  replacePlaceholdersInValues,
  saveBase64ToStorage,
} from "@util/helper";
import { useAdElements, useSyncAdCopyFromCache, useToggle } from "@util/hooks";

import { AdCopyElements, AdElement, Fieldset, Option, SelectedTemplate } from "@state/types";
import { Button, Loading } from "@global";
import { Rnd } from "react-rnd";
import { appPaths } from "@util/constants";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import { useConvertElementsToZipFile, useSaveTemplate } from "@util/firebaseHooks";
import { useStore } from "@state/store";
import { CanvasSize } from "@util/types";
import { useEditorHooks } from "@util/editorHooks";
import { getCopyBase64 } from "@util/canvasHelper";

export default function Editor() {
  if (!isBrowser()) return null;
  //store
  const { order, adCopy, setUsingTemplate, templateAvailable, saveAdCopy, setAdCopy } = useStore();

  //state
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [selectedTextIndex, setSelectedTextIndex] = useState(-1);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(-1);
  const [updating, setUpdating] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [quillValue, setQuillValue] = useState("");
  const [notes, setNotes] = useState(adCopy?.notes ?? "");
  const [overrideBackgroundColour, setOverrideBackgroundColour] = useState("#ffffff");
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>();
  const [bgOpacity, setBgOpacity] = useState(1);
  const [savingPng, setSavingPng] = useState(false);
  const [gridVisible, toggle, setGridVisible] = useToggle();
  const [overrideCanvasSize, setOverrideCanvasSize] = useState<CanvasSize | null>(null);
  const [savedElements, setSavedElements] = useState<AdCopyElements>();
  const [selectorVisible, setSelectorVisible] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate | null>(null);
  const [fieldset, setFieldset] = useState<Fieldset>({
    gridcode: "",
    fields: [
      {
        name: "",
        value: "",
      },
    ],
  });
  const [menuOption, setMenuOption] = useState<Option>();

  const [selectedShape, setSelectedShape] = useState<AdElement | undefined>();

  //refs
  const shapeElementsRef = useRef<HTMLDivElement[]>([]);
  const textElementsRef = useRef<HTMLDivElement[]>([]);
  const imageElementsRef = useRef<HTMLImageElement[]>([]);
  const advertBoardRef = createRef<HTMLDivElement>();

  const shouldShowTemplates = Boolean(process.env.GATSBY_SHOW_TEMPLATES);

  //hooks
  const { loading, setLoading } = useSyncAdCopyFromCache(
    order?.id,
    order?.product?.productId,
    setNotes,
    setSavedElements,
  );

  const { saveTemplate, getTemplate } = useSaveTemplate();

  const handleClearAdCopy = () => {
    setSavedElements(undefined);
    setBackgroundUrl(undefined);
    setOverrideBackgroundColour("#ffffff");
    setTextElements([]);
    setImageElements([]);
    setShapeElements([]);
  };

  const restoreAdCopyFromFirebase = async (templateId?: string) => {
    if (!templateId) return;

    //Clear all first
    setSavedElements(undefined);
    setBackgroundUrl(undefined);
    setOverrideBackgroundColour("#ffffff");
    setTextElements([]);
    setImageElements([]);
    setShapeElements([]);

    const templateData = await getTemplate(templateId);

    // const fieldset = getFieldset(templateData?.adCopyCode);

    if (templateData) {
      const swappedData = replacePlaceholdersInValues(
        templateData.adCopy.textElements,
        selectedTemplate?.formData,
      );
      setOverrideCanvasSize(templateData.canvas);

      //check templateAdcopy for logo files
      const swappedLogo = selectedTemplate?.formData?.logo
        ? templateData.adCopy.imageElements.map((image: any) => {
            if (image.istemplateLogo) {
              return {
                ...image,
                value: selectedTemplate?.formData?.logo,
              };
            }
            return image;
          })
        : templateData.adCopy.imageElements;

      const to = {
        ...templateData.adCopy,
        textElements: swappedData,
        imageElements: swappedLogo,
      };

      setSavedElements(to);
      setSelectedTemplate({ ...templateData, formData: selectedTemplate?.formData });
    }
  };

  const [imageElements, setImageElements] = useAdElements(
    selectedImageIndex,
    advertBoardRef,
    imageElementsRef,
    updating,
    zoom,
    savedElements?.imageElements,
  );
  const [textElements, setTextElements] = useAdElements(
    selectedTextIndex,
    advertBoardRef,
    textElementsRef,
    updating,
    zoom,
    savedElements?.textElements,
  );
  const [shapeElements, setShapeElements] = useAdElements(
    selectedShapeIndex,
    advertBoardRef,
    shapeElementsRef,
    updating,
    zoom,
    savedElements?.shapeElements,
  );
  const [_zipLoading, _hasSent, convertToZip] = useConvertElementsToZipFile();
  const { backgroundColourUpdated, backgroundUrlUpdated, stepBackwards } = useEditorHooks({
    imageElements,
    textElements,
    shapeElements,
    overrideBackgroundColour,
    backgroundUrl,
    setImageElements,
    setTextElements,
    setShapeElements,
    setOverrideBackgroundColour,
    setBackgroundUrl,
  });
  //side effects

  useEffect(() => {
    if (savedElements?.backgroundColour) {
      setOverrideBackgroundColour(savedElements.backgroundColour);
    }
    if (savedElements?.backgroundUrl) {
      setBackgroundUrl(savedElements.backgroundUrl);
    }
    if (savedElements?.backgroundTransparency) {
      setBgOpacity(savedElements.backgroundTransparency);
    }
  }, [savedElements]);

  useEffect(() => {
    if (quillValue === "") {
      setSelectedTextIndex(-1);
    }
  }, [quillValue]);

  //handle functions
  const handleRemoveElement = (type: "image" | "text" | "shape", indexToDelete: number) => {
    const mapToRemove = (elements: AdElement[]) =>
      elements.map((element, index) =>
        indexToDelete === index ? { toRemove: true, ...element } : element,
      );

    if (type == "shape" && shapeElements) {
      const updatedShapes = mapToRemove(shapeElements);
      setShapeElements(updatedShapes);
      setSelectedShapeIndex(-1);
    }
    if (type == "image" && imageElements) {
      const updatedImages = mapToRemove(imageElements);
      setImageElements(updatedImages);
      setSelectedImageIndex(-1);
    }
    if (type == "text" && textElements) {
      const updatedTexts = mapToRemove(textElements);
      setTextElements(updatedTexts);
      setSelectedTextIndex(-1);
    }
  };

  const handleSaveImage = (imageElement: AdElement) => {
    if (imageElements == null || imageElements.length === 0) {
      setImageElements([imageElement]);
      return;
    }
    setImageElements(prevImages => (prevImages ? [...prevImages, imageElement] : [imageElement]));
  };

  const handleSaveShape = (shapeElement: AdElement) => {
    if (shapeElements == null || shapeElements.length === 0) {
      setShapeElements([shapeElement]);
      return;
    }
    if (selectedShape != null) {
      const updatedElements = shapeElements.map(element => {
        if (element.id === selectedShape.id) {
          return {
            ...element,
            shape: shapeElement.shape,
          };
        }
        return element;
      });

      return setShapeElements(updatedElements);
    }
    setShapeElements(prevShapes => (prevShapes ? [...prevShapes, shapeElement] : [shapeElement]));
  };

  const handleClearSelectedShape = () => {
    setSelectedShape(undefined);
    setSelectedShapeIndex(-1);
  };

  const handleSaveRichText = () => {
    if (quillValue == null || quillValue === "") return;
    const text = { value: quillValue };
    if (textElements == null || textElements.length === 0) {
      setTextElements([text]);
      setQuillValue("");
      return;
    }
    if (selectedTextIndex > -1) {
      const updatedElements = textElements.map((element, index) => {
        if (index === selectedTextIndex) {
          return { ...element, value: text.value };
        }
        return element;
      });
      setTextElements(updatedElements);
      setQuillValue("");
      return;
    }

    setTextElements(prevRichText => (prevRichText ? [...prevRichText, text] : [text]));
    setQuillValue("");
  };

  const handleSaveBackgroundColour = (backgroundColour: string) => {
    setOverrideBackgroundColour(backgroundColour);
    backgroundColourUpdated(backgroundColour);
  };

  const handleSaveBackgroundImage = (imageElement: AdElement) => {
    // if (imageElements == null || imageElements.length === 0) {
    // setImageElements([imageElement]);
    setBgOpacity(imageElement.opacity ?? 1);
    setBackgroundUrl(imageElement.value);
    backgroundUrlUpdated(imageElement.value);
    return;
    // }
    // setImageElements(prevImages => (prevImages ? [...prevImages, imageElement] : [imageElement]));
  };

  //change navigator tab
  const handleElementSelect = (type: string) => {
    if (type == "shape") {
      setMenuOption("canvas");
    }
    if (type == "image") {
      setMenuOption("image");
    }
    if (type == "text") {
      setMenuOption("text");
    }
  };

  const handleUpdating = (isUpdating: boolean) => () => setUpdating(isUpdating);

  const handleDeselectElementIndex = (removeGrid?: boolean) => {
    setSelectedTextIndex(-1);
    setSelectedImageIndex(-1);
    setSelectedShapeIndex(-1);
    if(removeGrid) setGridVisible(false);
  };

  const handleNavigateToPreview = () => navigate(appPaths.preview);

  const handleDownloadZip = async () => {
    setSavingPng(true);
    handleDeselectElementIndex(true);
    toast.info("Saving zip file...");
    const copy = await getCopyBase64(advertBoardRef);
    if (!copy) {
      toast.error("Error converting ad copy to image");
      return;
    }
    const adCopyToSave = getAdCopyToSave({
      imageElements,
      textElements,
      shapeElements,
      overrideBackgroundColour,
      backgroundUrl,
      bgOpacity,
      order,
      notes,
      copy,
    });
    await convertToZip(undefined, true, adCopyToSave);
    setSavingPng(false);
  };

  const handleSaveAdCopy = async (navigateCallback?: () => void) => {
    if (!imageElements && !textElements && navigateCallback) {
      navigateCallback();
      return;
    }
    if (zoom !== 1) {
      toast.error("Please set the zoom to 100% before continuing");
      return;
    }
    if (advertBoardRef.current == null) return;

    handleDeselectElementIndex(true);
    toast.info("Taking snapshot... Please Do Not close this window");
    setLoading(true);

    try {
      const copy = await getCopyBase64(advertBoardRef);
      if (copy) {
        const adCopyToSave = getAdCopyToSave({
          imageElements,
          textElements,
          shapeElements,
          overrideBackgroundColour,
          backgroundUrl,
          bgOpacity,
          order,
          notes,
          copy,
        });

      //   const size = 400;
      //   console.log(
      //     "%c Image",
      //     `background-image: url('${copy}');
      //      background-size: contain;
      //      background-repeat: no-repeat;
      //      background-position: center;
      //      padding: ${size / 2}px ${size / 2}px;
      //      line-height: ${size}px;
      //      font-size: 1px;`
      // );

        setAdCopy(adCopyToSave);
        const firebaseID = `${order?.id}-${order?.product?.productId}`;

        const localCache = await getFileFromCache(order?.id!);
        if (localCache) {
          console.log("deleting localCache", localCache);
          await caches.delete(order?.id!);
        }
        saveAdCopy(adCopyToSave, firebaseID);
        if (navigateCallback) return navigateCallback();
        setLoading(false);
        return adCopyToSave;
      } else {
        toast.error("Error converting ad copy to image");
        setLoading(false);
      }
    } catch (error) {
      toast.error(`Error saving ad copy: ${error}`);
      setLoading(false);
    }
  };

  // save template
  const handleSaveTemplate = async (formData: any) => {
    // if (adCopy == null) return;

    const saveAdCopyData = await handleSaveAdCopy();

    if (!saveAdCopyData?.copy) {
      toast.error("Error saving ad copy, please try again");
      return;
    }

    toast.info("Saving template...");

    const id = `${formData.templateName.replace(/\s/g, "-")}-${formData.adCopyCode}`;
    const storagePath = `templatePreviews/${id}.png`;
    const thumb = await saveBase64ToStorage(saveAdCopyData?.copy, storagePath);

    const toFirebase = {
      adCopy: saveAdCopyData,
      templateName: formData.templateName,
      adcopyCode: formData.adCopyCode,
      canvas: {
        width: formData.canvasWidth,
        height: formData.canvasHeight,
      },
      id: id,
      thumbnail: thumb,
      templateEnabled: formData.templateEnabled,
    };

    saveTemplate(toFirebase, id ?? "");
  };

  const handleSaveOverrideCanvas = async (width: number, height: number) => {
    if (width && height) {
      setOverrideCanvasSize({ width, height });
    }
  };

  const updatingProps = {
    onDragStart: handleUpdating(true),
    onDragStop: handleUpdating(false),
    onResizeStart: handleUpdating(true),
    onResizeStop: handleUpdating(false),
  };

  return (
    <TextEditorProvider
      valueProps={{
        selectedTextIndex,
        selectedImageIndex,
        selectedShapeIndex,
        imageElements,
        onSaveRichText: handleSaveRichText,
        quillState: [quillValue, setQuillValue],
        notesState: [notes, setNotes],
        zoomState: [zoom, setZoom],
        onRemoveElement: handleRemoveElement,
      }}
    >
      <AppLayout
        hideFooter
        currentPath="editor"
        onRightButtonClick={() => handleSaveAdCopy(handleNavigateToPreview)}
        // onBackClick={() => handleSaveAdCopy(handleNavigateBack)}
        historyBack={stepBackwards}
      >
        {selectorVisible && !templateAvailable.pickerSelected && shouldShowTemplates ? (
          <></>
        ) : (
          <EditorMenu
            option={menuOption}
            setOption={setMenuOption}
            zoomState={[zoom, setZoom]}
            onClearElements={handleClearAdCopy}
            gridState={[gridVisible, toggle]}
          />
        )}
        <Container position="relative" backgroundColor="grey">
          {selectorVisible && !templateAvailable.pickerSelected && shouldShowTemplates && (
            <Container
              position="absolute"
              width="100%"
              height="100%"
              backgroundColor="athensGray"
              zIndex={100}
            >
              <EditorPicker
                setUsingTemplate={setUsingTemplate}
                setSelectorVisible={setSelectorVisible}
                loading={loading}
                hasExistingAdCopy={adCopy != null}
                resetAdcopy={handleClearAdCopy}
              />
            </Container>
          )}

          <EditorTool
            onSaveImage={handleSaveImage}
            onSaveBackgroundColour={handleSaveBackgroundColour}
            onSetShape={handleSaveShape}
            onSaveBackgroundImage={handleSaveBackgroundImage}
            onClearBackgroundImage={() => setBackgroundUrl(undefined)}
            onSaveTemplate={handleSaveTemplate}
            onSaveCanvasSize={handleSaveOverrideCanvas}
            onSelectFirebaseTemplate={restoreAdCopyFromFirebase}
            selectedTemplate={selectedTemplate}
            onSetSelectedTemplate={setSelectedTemplate}
            fieldset={fieldset}
            setFieldset={setFieldset}
            option={menuOption}
            selectedShape={selectedShape}
            clearSelectedShape={handleClearSelectedShape}
            overrideBackgroundColour={overrideBackgroundColour}
            setOption={setMenuOption}
          />

          {order ? (
            <AdvertBoard
              ref={advertBoardRef}
              zoomState={[zoom, setZoom]}
              gridState={[gridVisible, toggle]}
              onClickOutside={handleDeselectElementIndex}
              overrideBackgroundColour={overrideBackgroundColour}
              backgroundUrl={backgroundUrl}
              bgOpacity={bgOpacity}
              overrideCanvasSize={overrideCanvasSize}
            >
              {shapeElements &&
                shapeElements.map((shapeElement, index) => {
                  const isSelected = index === selectedShapeIndex;
                  const { position, dimension, toRemove, value, shape } = shapeElement;
                  if (toRemove) return null;

                  return (
                    <Rnd
                      {...updatingProps}
                      key={index}
                      onMouseDown={() => {
                        handleDeselectElementIndex();
                        handleElementSelect("shape");
                        setSelectedShape(shapeElement);
                        setSelectedShapeIndex(index);
                      }}
                      style={isSelected ? { zIndex: 1 } : undefined}
                      resizeHandleStyles={isSelected ? RndStyle() : undefined}
                      default={{
                        x: position?.x ?? 0,
                        y: position?.y ?? -150,
                        width: dimension?.width ?? "80px",
                        height: dimension?.height ?? "80px",
                      }}
                    >
                      <Shape
                        ref={element => {
                          if (shapeElementsRef.current == null || element == null) return;
                          shapeElementsRef.current[index] = element;
                        }}
                        shapeName={value}
                        background={shape?.background}
                        border={shape?.border}
                        height={
                          shape?.borderWidth ? `calc(100% - ${shape?.borderWidth * 2}px)` : "100%"
                        }
                        width={
                          shape?.borderWidth ? `calc(100% - ${shape?.borderWidth * 2}px)` : "100%"
                        }
                      />
                    </Rnd>
                  );
                })}

              {imageElements &&
                imageElements.map((image, index) => {
                  const isSelected = index === selectedImageIndex;
                  const { position, dimension, toRemove, id } = image;
                  if (toRemove) return null;
                  return (
                    <Rnd
                      {...updatingProps}
                      lockAspectRatio
                      key={id ?? index}
                      onMouseDown={() => {
                        handleDeselectElementIndex();
                        handleElementSelect("image");
                        setSelectedImageIndex(index);
                      }}
                      style={isSelected ? { zIndex: 1 } : undefined}
                      resizeHandleStyles={isSelected ? RndStyle() : undefined}
                      default={{
                        x: position?.x ?? 0,
                        y: position?.y ?? -150,
                        width: dimension?.width ?? "auto",
                        height: dimension?.height ?? "auto",
                      }}
                      position={{ x: position?.x ?? 0, y: position?.y ?? 0 }}
                      size={{
                        width: dimension?.width ?? "auto",
                        height: dimension?.height ?? "auto",
                      }}
                    >
                      <ImageElement>
                        <img
                          src={image.value}
                          ref={element => {
                            if (imageElementsRef.current == null || element == null) return;
                            imageElementsRef.current[index] = element;
                          }}
                          style={{
                            height: "100%",
                            width: "100%",
                            pointerEvents: "none",
                            userSelect: "none",
                            opacity: image.opacity ?? 1,
                          }}
                        />
                      </ImageElement>
                    </Rnd>
                  );
                })}

              {textElements &&
                textElements.map((textElement, index) => {
                  const isSelected = index === selectedTextIndex;
                  const { value, dimension, position, toRemove } = textElement;
                  if (toRemove) return null;

                  return (
                    <Rnd
                      {...updatingProps}
                      key={index}
                      position={{
                        x: position?.x ? position?.x - 1 : 0,
                        y: position?.y ? position?.y - 3 : 0,
                      }}
                      size={{
                        width: dimension?.width ? `${dimension?.width + 2}px` : "auto",
                        height: dimension?.height ? `${dimension?.height + 2}px` : "auto",
                      }}
                      onMouseDown={() => {
                        handleDeselectElementIndex();
                        handleElementSelect("text");
                        setQuillValue(value);
                        setSelectedTextIndex(index);
                      }}
                      style={
                        isSelected
                          ? { zIndex: 3, border: "1px dotted black" }
                          : { zIndex: 2, border: "1px dotted transparent" }
                      }
                      default={{
                        x: position?.x ?? 0,
                        y: position?.y ?? 0,
                        width: dimension?.width ? `${dimension?.width + 2}px` : "auto",
                        height: dimension?.height ? `${dimension?.height + 2}px` : "auto",
                      }}
                    >
                      <TextElement
                        ref={element => {
                          if (textElementsRef.current == null || element == null) return;
                          textElementsRef.current[index] = element;
                        }}
                        width={dimension?.width}
                        dangerouslySetInnerHTML={{ __html: value }}
                      />
                    </Rnd>
                  );
                })}

              {loading && (
                <Overlay opacity={0.8} className="overlay-loader">
                  <Container
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Loading width="50px" height="50px" thickness={4} />
                    <p style={{ color: "white", fontSize: 32 }}>Loading changes...</p>
                  </Container>
                </Overlay>
              )}
            </AdvertBoard>
          ) : (
            <AdvertBoardWrapper>
              <AdvertContainer>
                <h2>No calendar found. Please select a calendar.</h2>
              </AdvertContainer>
            </AdvertBoardWrapper>
          )}

          <AbsoluteContainer bottom="30px" right="30px" columnGap={15}>
            <Button loading={savingPng} linkText="Download zip" onClick={handleDownloadZip} />
            <Button linkText="Save to order" onClick={handleSaveAdCopy} />
          </AbsoluteContainer>
        </Container>
      </AppLayout>
    </TextEditorProvider>
  );
}
