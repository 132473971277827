import { AdCopyElements, AdElement } from "@state/types";
import { useEffect, useState } from "react";
import { debounce, filterRemovedAdElements } from "./helper";
import { useDebounce } from "./hooks";

interface HistoryProps {
  imageElements: AdCopyElements[];
  textElements: AdCopyElements[];
  shapeElements: AdCopyElements[];
  backgroundColour: string;
  backgroundUrl: string;
  setImageElements: (imageElements: AdCopyElements[]) => void;
  setTextElements: (textElements: AdCopyElements[]) => void;
  setShapeElements: (shapeElements: AdCopyElements[]) => void;
  setBackgroundColour: (backgroundColour: string) => void;
  setOverrideBackgroundColour: (backgroundUrl: string) => void;
  setBackgroundUrl: (backgroundUrl: string) => void;
}

interface HistoryType {
  imageElements: AdElement[] | undefined;
  textElements: AdElement[] | undefined;
  shapeElements: AdElement[] | undefined;
  backgroundColour: string;
  backgroundUrl: string;
}

function arraysEquality(array1: AdElement[] | undefined | any[], array2: any[]) {
  if (array1 == null && array2 == null) return true;

  return (
    array1?.length === array2?.length &&
    array1?.every((value, index) => JSON.stringify(value) === JSON.stringify(array2?.[index]))
  );
}

function stringEquality(string1: string, string2: string) {
  if (string1 == null || string2 == null) return true;
  return Boolean(string1 === string2);
}

export const useEditorHooks = (props: any) => {
  const {
    imageElements,
    textElements,
    shapeElements,
    backgroundColour,
    backgroundUrl,
    setImageElements,
    setTextElements,
    setShapeElements,
    setOverrideBackgroundColour,
    setBackgroundUrl,
  } = props;

  const [history, setHistory] = useState<HistoryType[] | null>(null);

  const saveHistory = (backgroundColourUpdate?: string) => {
    if (
      imageElements == undefined &&
      textElements == undefined &&
      shapeElements == undefined &&
      backgroundColour == undefined &&
      backgroundUrl == null
    )
      return;

    if (history == null) {
      const adCopyElements = {
        imageElements: filterRemovedAdElements(imageElements),
        textElements: filterRemovedAdElements(textElements),
        shapeElements: filterRemovedAdElements(shapeElements),
        backgroundColour: backgroundColourUpdate ?? "#fff",
        backgroundUrl: backgroundUrl ?? "",
      };

      return setHistory([adCopyElements]);
    }
    const adCopyElements = {
      imageElements: filterRemovedAdElements(imageElements),
      textElements: filterRemovedAdElements(textElements),
      shapeElements: filterRemovedAdElements(shapeElements),
      backgroundColour:
        backgroundColourUpdate ?? history[history.length - 1]?.backgroundColour ?? "",
      backgroundUrl: backgroundUrl ?? history[history.length - 1]?.backgroundUrl ?? "",
    };

    if (history.length > 9) {
      const newHistory = history.slice(1);
      setHistory([...newHistory, adCopyElements]);
    } else {
      setHistory([...history, adCopyElements]);
    }
  };

  const debounceHistoryUpdate = debounce(saveHistory, 1000);

  const backgroundColourUpdated = (colour: string) => {
    console.log("backgroundColourUpdated", { colour });

    debounceHistoryUpdate(colour);
  };

  const backgroundUrlUpdated = (backgroundUrl: string) => {
    debounceHistoryUpdate(backgroundUrl);
  };

  const stepBackwards = () => {
    if (history == null) return;
    const lastHistory = history[history.length - 2];
    if (lastHistory == null) return;

    setImageElements(lastHistory?.imageElements);
    setTextElements(lastHistory?.textElements);
    setShapeElements(lastHistory?.shapeElements);
    setOverrideBackgroundColour(lastHistory?.backgroundUrl);
    setBackgroundUrl(lastHistory?.backgroundUrl);

    const updatedHistory = history.slice(0, history.length - 1);
    setHistory(updatedHistory);
  };

  useEffect(() => {
    if (history == null) {
      return debounceHistoryUpdate();
    }
    //only set to history if there is a change
    const imageEquality = arraysEquality(imageElements, history[history.length - 1]?.imageElements);
    const textEquality = arraysEquality(textElements, history[history.length - 1]?.textElements);
    const shapeEquality = arraysEquality(shapeElements, history[history.length - 1]?.shapeElements);
    const backgroundColourEquality = stringEquality(
      backgroundColour,
      history[history.length - 1]?.backgroundColour,
    );
    const backgroundUrlEquality = stringEquality(
      backgroundUrl,
      history[history.length - 1]?.backgroundUrl,
    );

    if (
      !!imageEquality &&
      !!textEquality &&
      !!shapeEquality &&
      !!backgroundColourEquality &&
      !!backgroundUrlEquality
    ) {
      return;
    }
    debounceHistoryUpdate();
  }, [imageElements, textElements, shapeElements, backgroundColour, backgroundUrl]);

  useEffect(() => {
    const undoButton = document.getElementById("history-undo");
    console.log({ history, undoButton });

    if (history == null || history?.length == 0) {
      if (undoButton) {
        undoButton.style.display = "none";
      }
    } else {
      if (undoButton) {
        undoButton.style.display = "block";
      }
    }
  }, [history]);

  return { backgroundColourUpdated, backgroundUrlUpdated, stepBackwards };
};
